<template>
  <li :class="{ active: expanded }" class="l-2">
    <ion-card color="white" class="card cursor-pointer text-bold">
      <ion-card-content class="pad-zero">
        <ion-grid>
          <ion-row class="ion-align-items-center pad-zero">
            <div v-if="orgObject.countSubOrgs >= 1" class="icon-container flex">
              <div @click="toggleChildren(id, cnodes.length)" class="valign cursor-pointer flex">
                <ion-icon v-if="cnodes.length && showChildren.includes(id)" name="md-remove" color="medium" class="icon-large"></ion-icon>
                <ion-icon v-else name="ios-add" color="medium" class="icon-large"></ion-icon>
              </div>
            </div>
            <div v-else class="icon-container">
              <ion-icon name="ios-add" color="light" class="icon-large"></ion-icon>
            </div>
            <ion-col @click="setOrganization(id, orgObject.name)" class="name flex">
              <div class="cursor-pointer">
                <div class="text-bold">{{ orgObject.name }}</div>
                <div class="text-muted text-small">{{ orgObject.countSubOrgs.toLocaleString() }} Sub Organizations</div>
              </div>
            </ion-col>
            <div v-if="0">
              <ion-col @mouseleave="dismissPopover" size="auto" class="stat-container pad-ten margin-right-ten flex radius-10 bg-grey-three">
                <div>
                  <IconChatBubble class="icon-small margin-right-ten icon-blue" />
                </div>
                <div>
                  <ion-row class="">
                    <ion-col class="margin-right-ten column-stretch-header">
                      <div class="text-muted text-small">Open Conversations</div>
                      <div class="info-text pad-right-ten text-black">
                        {{ orgObject.countOpenInteractions.toLocaleString() }}
                      </div>
                    </ion-col>
                    <ion-col class="column-stretch-header">
                      <div class="text-muted text-small">Awaiting Responses</div>
                      <div class="info-text pad-right-ten text-black">
                        {{ orgObject.countAwaitingResponse.toLocaleString() }}
                      </div>
                    </ion-col>
                  </ion-row>
                </div>
              </ion-col>

              <ion-col @mouseenter="showPopover($event, 'tasks', id)" @mouseleave="dismissPopover" size="auto" class="stat-container pad-ten margin-right-ten flex bg-grey-three radius-10">
                <div>
                  <IconCheckCircle class="icon-small margin-right-ten icon-blue" />
                </div>
                <div>
                  <ion-row class="">
                    <ion-col class="margin-right-ten column-stretch-header">
                      <div class="text-muted text-small">Open Tasks</div>
                      <div class="info-text pad-right-ten text-black">
                        {{ orgObject.countOpenTasks.toLocaleString() }}
                      </div>
                    </ion-col>
                    <ion-col class="column-stretch-header">
                      <div class="text-muted text-small">Assigned to Me</div>
                      <div class="info-text pad-right-ten text-black">
                        {{ orgObject.countAssignedToMe.toLocaleString() }}
                      </div>
                    </ion-col>
                  </ion-row>
                </div>
              </ion-col>

              <ion-col @mouseleave="dismissPopover" size="auto" class="stat-container pad-ten margin-right-ten flex bg-grey-three radius-10">
                <div>
                  <IconPrograms class="icon-small margin-right-ten icon-blue" />
                </div>
                <div>
                  <ion-row class="">
                    <ion-col class="margin-right-ten column-stretch-header">
                      <div class="text-muted text-small">Programs Live</div>
                      <div class="info-text pad-right-ten text-black">
                        {{ orgObject.countProgramsLive.toLocaleString() }}
                      </div>
                    </ion-col>
                    <ion-col class="column-stretch-header">
                      <div class="text-muted text-small">Patients Enrolled</div>
                      <div class="info-text pad-right-ten text-black">
                        {{ orgObject.countPatientsEnrolled.toLocaleString() }}
                      </div>
                    </ion-col>
                  </ion-row>
                </div>
              </ion-col>

              <ion-col @mouseenter="showPopover($event, 'events', id)" @mouseleave="dismissPopover" size="auto" class="stat-container pad-ten margin-right-zero flex bg-grey-three radius-10">
                <div>
                  <IconWarningOutline class="icon-small margin-right-ten icon-blue" />
                </div>
                <div>
                  <ion-row class="">
                    <ion-col class="margin-right-ten column-stretch-header">
                      <div class="text-muted text-small">Event Notifications(past 48 hours)</div>
                      <div class="info-text pad-right-ten text-black">
                        {{ orgObject.countEventNotifications.toLocaleString() }}
                      </div>
                    </ion-col>
                  </ion-row>
                </div>
              </ion-col>

              <ion-col v-if="$isMobile" size="auto" class="mobile-view-more">
                <button @click="setOrganization(id, orgObject.name)" class="prime-button button-block button-secondary">
                  <IconCarrot class="icon-small icon-blue margin-left-zero margin-right-zero cursor-pointer valign" />
                </button>
              </ion-col>
            </div>
          </ion-row>
        </ion-grid>
        <div class="expander-left" />
      </ion-card-content>
    </ion-card>

    <ul :key="JSON.stringify(showChildren)" v-if="cnodes.length && showChildren.includes(id)">
      <organization-item v-for="treenode in cnodes" :key="treenode.name" :orgObject="treenode" :nodes="treenode.orgs" :id="treenode.id" />
    </ul>
  </li>
</template>

<script>
/* eslint-disable no-console */
import IconCarrot from "@/components/Global/Icons/IconCarrot.vue";
import IconChatBubble from "@/components/Global/Icons/IconChatBubble";
import IconCheckCircle from "@/components/Global/Icons/IconCheckCircle";
import IconPrograms from "@/components/Global/Icons/IconPrograms";
import IconWarningOutline from "@/components/Global/Icons/IconWarningOutline";
import PopoverOrganization from "@/components/Roster/PopoverOrganization.vue";
import { send as httpSend } from "@/services/Api";
import { EventBus } from "@/services/Events";
import store from "@/store";
import { addIcons } from "ionicons";
import { add, remove } from "ionicons/icons";
import { mapActions, mapGetters } from "vuex";

addIcons({
  "ios-add": add.ios,
  "md-add": add.md,
  "md-remove": remove.md
});

export default {
  name: "OrganizationItem",

  props: {
    orgObject: {
      type: Object,
      required: true
    },
    nodes: {
      type: Array,
      default: () => [],
      required: false
    },
    hasChildren: { type: Boolean },
    id: { type: Number }
  },

  components: {
    IconChatBubble,
    IconCheckCircle,
    IconWarningOutline,
    IconPrograms,
    IconCarrot
  },

  data() {
    return {
      timout: null,
      delay: 500,
      expanded: false,
      showChildren: [],
      cnodes: []
    };
  },

  computed: {
    ...mapGetters({
      children: "organizations/getOrganizationChildren"
    })
  },

  mounted() {
    this.showChildren = this.children;
    this.cnodes = this.nodes;
  },

  methods: {
    ...mapActions({
      setOrganizationId: "organizations/AssignOrganizationId",
      fetchOrganizations: "organizations/AssignOrganization",
      assignChildren: "organizations/AssignChildren",
      setChildOrgIds: "organizations/AssignChildOrganizationIds"
    }),

    setOrganization(id, name) {
      this.setOrganizationId({ id, name });

      if (store.state.routerKey !== id) {
        store.commit("setRouterKey", id);
      }

      let r = this.$router.resolve({
        path: "/professional/roster"
      });
      window.location.assign(r.href);
    },

    async toggleChildren(id, childrenAvailable) {
      this.expanded = !this.expanded;

      if (childrenAvailable) {
        this.showChildren.includes(id) ? this.showChildren.splice(this.showChildren.indexOf(id), 1) : this.showChildren.push(id);
      } else {
        try {
          const path = `${document.config.organizationsList}/${id}`;
          const method = "get";

          this.cnodes = await httpSend({ path, method, authToken: true })
            .then(response => {
              return response.data;
            })
            .catch(error => {
              this.$ionic.toastController
                .create({
                  header: "Failed to load Broadcast Message History",
                  message: error,
                  duration: 7000,
                  position: "top"
                })
                .then(m => m.present());
            });
        } catch (e) {
          this.$ionic.toastController
            .create({
              header: "Error Loading Data",
              message: e,
              duration: 7000,
              position: "top"
            })
            .then(m => m.present());
        }

        if (this.cnodes.length) {
          // Set cKey value on parent
          EventBus.$emit("setChildren", id);

          // Assings the newly loaded sub organizations
          this.assignChildren({ id, children: this.cnodes });

          if (!this.showChildren.includes(id)) this.showChildren.push(id);

          // Set org Ids in state
          this.setChildOrgIds(this.showChildren);

          return;
        }
      }
    },

    /**
     * Dismisses the popover on mouseleave
     */
    dismissPopover() {
      clearTimeout(this.timout);
      this.$ionic.popoverController.dismiss();
    },

    /**
     * Functionality for displaying the popover
     * Delay added to limit API calls
     */
    showPopover(ev, type, orgId) {
      this.timout = setTimeout(() => {
        this.$ionic.popoverController
          .create({
            component: PopoverOrganization,
            alignment: "center",
            backdropDismiss: true,
            dismissOnSelect: true,
            showBackdrop: false,
            arrow: true,
            reference: "event",
            side: "top",
            event: ev,
            mode: "ios",
            cssClass: "organization-popover",
            componentProps: {
              data: { type, orgId, popover: this.$ionic.popoverController }
            }
          })
          .then(p => p.present());
      }, this.delay);
    }
  }
};
</script>

<style lang="scss">
.organization-popover {
  pointer-events: none !important;

  &.ios {
    .popover-arrow {
      display: block;
      margin-top: -20px;

      &:after {
        border-radius: 0;
        background-color: var(--ion-text-color);
      }
    }
  }

  .popover-content {
    margin-top: -20px;
  }
}
</style>

<style scoped lang="scss">
.card {
  margin-left: 0;
  margin-right: 0;

  .icon-container {
    width: 34px;
  }

  .mobile-view-more {
    margin-right: 5px;
  }

  .prime-button {
    padding-right: 2px;
    padding-left: 2px;

    svg {
      margin-right: 0px;
      margin-top: 0;
    }
  }

  .stat-container {
    display: none;

    @include break-min($tablet) {
      display: flex;
    }
  }
}
.flex {
  display: flex;
}
.radius-10 {
  border-radius: 10px;
}
ul {
  list-style: none;
  padding-left: 20px;

  @include break-min($tablet) {
    padding-left: 40px;
  }
}
h1 {
  color: #2b2b2b;
  font-size: 30px;
}
.column-stretch-header {
  flex: 0 0 auto;
  width: auto;
}
.icon-container {
  width: 34px;

  &.small {
    width: 10px;
  }
}
.sc-ion-card-ios-h {
  margin-bottom: 10px;
}
</style>
