<template>
  <ion-list class="full-width text-left text-small text-thin pad-five">
    <ion-item v-for="item in toolTipData" :key="item.id" color="blue" class="valign margin-left-zero pad-top-five pad-bottom-five pad-left-zero pad-right-zero flex bottom-divider-thin-transparent">
      <div class="full-width valign flex-between">
        <div class="margin-right-thirty text-white text-small">{{ item.name }}</div>
        <div class="text-bold text-white text-small">{{ item.count.toLocaleString() }}</div>
      </div>
    </ion-item>
  </ion-list>
</template>

<script>
/* eslint-disable no-console */
import { send as httpSend } from "@/services/Api";

export default {
  name: "PopoverOrganization",
  components: {},
  data() {
    return {
      toolTipData: []
    };
  },
  mounted() {
    this.fetchTooltipData();
  },
  methods: {
    async fetchTooltipData() {
      try {
        const path = `${document.config.organizationsList}/${this.orgId}/${this.type}`;
        const method = "get";

        this.toolTipData = await httpSend({ path, method, authToken: true })
          .then(response => {
            return response.data;
          })
          .catch(e => {
            this.$ionic.toastController
              .create({
                header: "Error loading popover data",
                message: e,
                duration: 7000,
                position: "top"
              })
              .then(m => m.present());
          });
      } catch (e) {
        this.$ionic.toastController
          .create({
            header: "Error Loading Data",
            message: e,
            duration: 7000,
            position: "top"
          })
          .then(m => m.present());
      }
    }
  }
};
</script>

<style lang="scss">
.organization-popover {
  .popover-content {
    padding: 5px;
    background-color: #0d1c2e;

    .item {
      height: 38px;

      &:last-child {
        border: none;
      }

      .input-wrapper {
        justify-content: space-between !important;
      }

      div {
        padding-left: 0;
      }

      .item-native {
        padding-left: 0;
      }
    }
  }
}
</style>
