<template>
  <PrimePage>
    <ion-app>
      <ion-content>
        <div class="prime-full-width-container full-height">
          <ion-row class="display-flex valign margin-top-twenty">
            <ion-col>
              <h4 class="view-title">Organizations Dashboard</h4>
            </ion-col>
          </ion-row>
          <ion-col>
            <organization-wrapper v-if="organizationsList.length > 0" :data="organizationsList" />
            <div v-else class="text-center pad-ten full-height">
              <div class="centerItems text-muted display-flex valign full-height full-width">
                <ion-spinner name="lines" class="spinner-large valign"></ion-spinner>
              </div>
            </div>
          </ion-col>
        </div>
      </ion-content>
    </ion-app>
  </PrimePage>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import PrimePage from "@/components/Global/PrimePage";
import OrganizationWrapper from "@/components/Organizations/OrganizationWrapper";

export default {
  name: "OrgsList",
  components: {
    PrimePage,
    OrganizationWrapper
  },

  data() {
    return {
      orgsList: this.organizationsList,
      expandedChildren: this.organizationChildren,
      isOpen: true
    };
  },

  computed: {
    ...mapGetters({
      organizationsList: "organizations/getOrganizations",
      organizationChildren: "organizations/getOrganizationChildren"
    })
  },

  beforeMount() {
    this.orgsList = this.organizationsList;
    this.expandedChildren = this.organizationChildren;
  },

  mounted() {
      this.fetchOrganizations();
  },

  methods: {
    ...mapActions({
      fetchOrganizations: "organizations/AssignOrganization"
    })
  }
};
</script>
<style lang="scss">
.view-title {
  margin-top: 0;

  @include break-min($tablet) {
    margin-top: 16px;
  }
}

.card {
  overflow: visible;
  position: relative;
}

.tree {
  > li {
    margin-bottom: 10px;

    @include break-min($tablet) {
      margin-bottom: 20px;
    }

    .card {
      margin-top: 0px !important;

      @include break-min($tablet) {
        margin-top: 15px !important;
      }
    }

    > ul {
      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 98px;
        left: 9px;
        width: 0;
        height: calc(100% - 165px);
        border: solid var(--ion-color-medium-shade);
        border-width: 0 0 2px 2px;

        @include break-min($tablet) {
          top: 137px;
          left: 18px;
          width: 0;
          height: calc(100% - 245px);
        }
      }

      &:last-child {
        &:after {
          content: none;
        }
      }
    }
  }

  ul {
    position: relative;

    li {
      position: relative;

      .card {
        margin-top: 0px !important;
        margin-bottom: 8px;

        @include break-min($tablet) {
          margin-bottom: 10px;
        }
      }

      &:before {
        content: "";
        display: block;
        position: absolute;
        top: -0.7rem;
        left: -11px;
        width: calc(0.2rem + 6px);
        height: calc(2.7rem + 1px);
        border: solid var(--ion-color-medium-shade);
        border-width: 0 0 2px 2px;

        @include break-min($tablet) {
          top: -0.7rem;
          left: -22px;
          width: calc(1.2rem + 4px);
          height: calc(4rem + 1px);
        }
      }

      &:after {
        content: "";
        display: block;
        position: absolute;
        top: -0.6rem;
        left: -11px;
        width: 0;
        height: calc(100% + 7px); // calc(7rem + 7px);
        border: solid var(--ion-color-medium-shade);
        border-width: 0 0 2px 2px;

        @include break-min($tablet) {
          top: 2.75rem;
          left: -22px;
          height: calc(100% + 7px); // calc(7rem + 1px);
        }
      }

      &:last-child {
        &:after {
          content: none;
        }
      }

      &.active {
        ul {
          li {
            .card {
              margin-top: 5px;
            }
          }
        }
      }

      ul {
        .l-2 {
          &:before {
            top: -0.6rem;
          }
        }
      }
    }
  }
}

.organization-popover {
  pointer-events: none !important;

  .popover-content {
    margin-top: -20px;
  }
}
</style>

<style scoped lang="scss">
.flex {
  display: flex;
}
.radius-10 {
  border-radius: 10px;
}
h1 {
  color: #2b2b2b;
  font-size: 30px;
}
.column-stretch-header {
  flex: 0 0 auto;
  width: auto;
}
</style>
