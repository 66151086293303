<template>
  <ul class="l-1" :class="counter !== 0 ? firstChildOnly : ''" :key="cKey">
    <organization-item v-for="treenode in data" :key="treenode.name" :orgObject="treenode" :nodes="treenode.orgs" :hasChildren="treenode.hasChildren" :id="treenode.id" />
  </ul>
</template>

<script>
import { EventBus } from "@/services/Events";
import OrganizationItem from "@/components/Organizations/Organization";

export default {
  name: "OrganizationWrapper",
  components: { OrganizationItem },

  props: {
    data: { type: Array }
  },

  data() {
    return {
      cKey: 3,
      firstChildOnly: "tree",
      counter: 0
    };
  },

  mounted() {
    ++this.counter;

    EventBus.$on("setChildren", id => {
      this.cKey = id;
    });
  }
};
</script>

<style scoped>
ul {
  padding: 0;
  list-style: none;
}
</style>
